<template>
  <b-modal
    size="xl"
    centered
    :ref="modalName"
    :id="modalName"
    class="vice-chair-modal"
  >
    <template #modal-header>
      <div>
        <h3 class="vice-chair-modal__header">Technical Board Vice-Chair Allocation</h3>
      </div>
    </template>
    <div class="vice-chair-modal__card border-0">
      <perfect-scrollbar :style="{height: '100%'}">
        <ifac-loader v-if="isLoading" class="vice-chair-modal__loader" />
        <div v-else>
          <div
            v-if="conferenceHasMaxCCs || conferenceHasMaxAvailableVCs"
            class="vice-chair-modal__banner"
          >
            {{ bannerMessage }}
          </div>
          <div class="vice-chair-modal__content">
            <div
              v-for="(item, index) in viceChairSelectionOptions"
              :key="index"
            >
              <div class="vice-chair-modal__block">
                <ifac-dropdown
                  title="Vice-Chair"
                  v-model="item.selection"
                  :list="viceChairDropdownOptions"
                  @input="handleViceChairSelection($event, index)"
                  class="vice-chair-modal__dropdown"
                ></ifac-dropdown>
                <ifac-base-button
                  v-if="index"
                  class="border-0"
                  @click="removeViceChair(index)"
                >
                  <i class="far fa-trash"></i>
                </ifac-base-button>
              </div>
              <ifac-base-text class="mb-2" text="Co-ordinating committee" />
              <div class="vice-chair-modal__cc-block">
                <div
                  v-for="(ccOption, i) in item.ccOptions"
                  :key="ccOption.id"
                >
                  <div
                    :class="[
                      'vice-chair-modal__cc-option',
                      ccOption.active && 'vice-chair-modal__cc-option--active'
                    ]"
                    @click="handleCheckboxChange(true, index, i)"
                  >
                    <ifac-base-text :text="ccOption.name" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
    <template #modal-footer>
      <div class="vice-chair-modal__footer">
        <ifac-base-button text="Close" @click="closeModal" />
        <ifac-base-button
          text="Add Vice Chair"
          :disabled="conferenceHasMaxCCs || conferenceHasMaxAvailableVCs"
          @click="addDefaultViceChair"
        />
        <ifac-base-button text="Save" type="secondary" @click="saveData" />
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  IfacBaseButton,
  IfacBaseText,
  IfacLoader,
  IfacPositionHelpers,
  IfacDropdown,
  IfacApprovalsHelpers,
} from '@ifac/ui';
import { mapGetters } from 'vuex';
import Affiliates from '@/services/Api/Affiliates';
import Approvals from '@/services/Api/Approvals';

export default {
  name: 'SetViceChairModal',
  components: {
    IfacBaseButton,
    IfacBaseText,
    IfacLoader,
    IfacDropdown,
  },
  props: {
    data: {
      type: Array,
      default: () => ([]),
    },
    conferenceId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      modalName: 'vice-chair-modal',
      isLoading: false,
      availableViceChairs: [],
      conferenceViceChairs: [],
      conferenceCCs: [],
      viceChairSelectionOptions: [],
      defaultConferenceCCList: [],
      viceChairPositionId: null,
      nodesToRemove: [],
    };
  },
  computed: {
    ...mapGetters({
      positionsData: 'positions/data',
      coordinatingCommittees: 'coordinatingCommittees/data',
    }),
    viceChairDropdownOptions() {
      const availableViceChairs = this.availableViceChairs.map((viceChair) => ({
        id: viceChair?.id,
        name: viceChair?.name,
      }));

      return [
        {
          id: '',
          name: 'Unassigned',
        },
        ...availableViceChairs,
      ];
    },
    conferenceHasMaxCCs() {
      return this.conferenceCCs?.length === this.viceChairSelectionOptions?.length;
    },
    conferenceHasMaxAvailableVCs() {
      return this.availableViceChairs?.length === this.viceChairSelectionOptions?.length;
    },
    bannerMessage() {
      if (this.conferenceHasMaxAvailableVCs) {
        return 'All Vice-Chairs have been allocated to this conference';
      }

      if (this.conferenceHasMaxCCs) {
        return 'All required CCs for this conference have been allocated';
      }

      return '';
    },
  },
  created() {
    this.$root.$on('bv::modal::show', (bvEvent) => {
      if (bvEvent.componentId === this.modalName) {
        this.resetData();
        this.getAffiliatesByViceChairPosition();
        this.setConferenceViceChairsAndCCs();
      }
    });
  },
  methods: {
    async getAffiliatesByViceChairPosition() {
      const viceChair = IfacPositionHelpers.findPositionByReference({
        data: this.positionsData,
        parentReference: 'name',
        parentValue: 'Technical Board',
        reference: 'name',
        value: 'Vice-Chair',
      });

      this.viceChairPositionId = viceChair.id;

      if (this.viceChairPositionId) {
        this.isLoading = true;
        try {
          const response = await Affiliates.get(
            1,
            9999,
            null,
            null,
            'desc',
            { position: this.viceChairPositionId, status: 'approved' },
            ['contactInfo'],
          );

          if (response?.status === 200 && response?.data?.data?.items?.length) {
            this.availableViceChairs = response?.data?.data?.items;
          }
        } catch (error) {
          console.error(error);
        }

        this.isLoading = false;
      }
    },
    handleCheckboxChange(event, viceChairindex, i) {
      const viceChairSelectionOptions = [...this.viceChairSelectionOptions];

      viceChairSelectionOptions.forEach((option, index) => {
        if (index === viceChairindex) {
          viceChairSelectionOptions[index].ccOptions[i].active = event;
        } else {
          viceChairSelectionOptions[index].ccOptions[i].active = false;
        }
      });

      // Reset and set updated options
      this.viceChairSelectionOptions = [];
      this.$nextTick(() => {
        this.viceChairSelectionOptions = viceChairSelectionOptions;
      });
    },
    resetData() {
      this.availableViceChairs = [];
      this.conferenceViceChairs = [];
      this.conferenceCCs = [];
      this.viceChairSelectionOptions = [];
      this.nodesToRemove = [];
    },
    handleViceChairSelection(selectedId, selectedIndex) {
      const viceChairSelectionOptions = [...this.viceChairSelectionOptions];

      viceChairSelectionOptions.forEach((option, index) => {
        if (index === selectedIndex) return;

        if (option.selection === selectedId) {
          viceChairSelectionOptions[index].selection = '';
        }
      });

      // Reset and set updated options
      this.viceChairSelectionOptions = [];
      this.$nextTick(() => {
        this.viceChairSelectionOptions = viceChairSelectionOptions;
      });
    },
    closeModal() {
      this.$refs['vice-chair-modal'].hide();
      this.$emit('close-modal');
    },
    addDefaultViceChair() {
      this.viceChairSelectionOptions.push({
        title: 'Vice-Chair (Technical Board)',
        selection: this.viceChairDropdownOptions[0].id,
        ccOptions: this.conferenceCCs.map(
          (cc, index) => this.setDefaultCCs(cc, index),
        ),
      });
    },
    removeViceChair(index) {
      this.viceChairSelectionOptions[index].ccOptions.forEach(
        (option, i) => {
          if (option.active) this.viceChairSelectionOptions[0].ccOptions[i].active = true;
        },
      );
      this.nodesToRemove.push(this.viceChairSelectionOptions[index].nodeId);
      this.viceChairSelectionOptions.splice(index, 1);
    },
    async saveData() {
      if (!this.isLoading) {
        this.isLoading = true;

        for (let index = 0; index < this.nodesToRemove.length; index++) {
          if (this.nodesToRemove[index]) {
            // eslint-disable-next-line no-await-in-loop
            await Approvals.deleteApprovalNode(this.nodesToRemove[index]);
          }
        }

        for (let index = 0; index < this.viceChairSelectionOptions.length; index++) {
          // eslint-disable-next-line no-await-in-loop
          await this.setApprovalTreeData(this.viceChairSelectionOptions[index], index);
        }

        this.isLoading = false;
        this.$emit('data-updated');
        this.closeModal();
      }
    },
    async setApprovalTreeData(option, index) {
      const existingApprovalTreeNode = this.data?.[0]?.children?.[index];
      const existingVC = existingApprovalTreeNode?.approval?.affiliate_id;
      const userSelectedVC = option?.selection;
      const approvalId = existingApprovalTreeNode?.approval?.id;
      let nodeId = option?.nodeId;

      if (!nodeId) {
        const response = await Approvals.setApprovalNode({
          conferenceId: this.conferenceId,
          positionId: this.viceChairPositionId,
        });

        nodeId = response?.data?.data?.id;
      }

      if ((existingVC && userSelectedVC) && (existingVC !== userSelectedVC)) {
        await Approvals.delete(approvalId);
        await Approvals.create({ treeId: nodeId, affiliateId: option.selection });
      } else if (existingVC && !userSelectedVC) {
        await Approvals.delete(approvalId);
      } else if (!existingVC && userSelectedVC) {
        await Approvals.create({ treeId: nodeId, affiliateId: option.selection });
      }

      for (let index = 0; index < option.ccOptions.length; index++) {
        const currentCCs = existingApprovalTreeNode?.children?.map((cc) => cc.id) || [];
        const isActive = option?.ccOptions?.[index]?.active;
        const ccId = option?.ccOptions?.[index]?.id || null;

        if (isActive && !currentCCs.includes(ccId)) {
          // eslint-disable-next-line no-await-in-loop
          await Approvals.assignCCToNewParent(ccId, { parentId: nodeId });
        }
      }

      return true;
    },
    setDefaultCCs(cc, index, active = false) {
      return {
        id: cc.id,
        name: IfacApprovalsHelpers.getApproversName(cc, this.coordinatingCommittees),
        active,
      };
    },
    setConferenceViceChairsAndCCs() {
      this.conferenceViceChairs = this.data?.[0]?.children;
      this.conferenceViceChairs.forEach((vc) => this.conferenceCCs.push(...vc.children));

      this.conferenceViceChairs.forEach((vc) => {
        this.viceChairSelectionOptions.push({
          title: 'Vice-Chair (Technical Board)',
          selection: vc?.approval?.affiliate_id || this.viceChairDropdownOptions[0].id,
          nodeId: vc?.id,
          ccOptions: this.conferenceCCs.map(
            (cc, index) => {
              const active = vc.children.map((child) => child.id).includes(cc.id);

              return this.setDefaultCCs(cc, index, active);
            },
          ),
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.vice-chair-modal {
  &__header {
    margin-bottom: 0;
  }

  &__card {
    height: 70vh;
  }

  &__loader {
    height: 100%;
  }

  &__footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__banner {
    text-align: center;
    border: 2px solid #FF6F00;
    border-radius: 3px;
    padding: 5px;
    background-color: rgba(#FF6F00, 0.05);
    font-weight: 500;
    margin-bottom: 1.5rem;
  }

  &__content {
    max-width: 90%;
  }

  &__block {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
  }

  &__dropdown {
    width: 700px;

    ::v-deep .dropdown-menu {
      width: 100%;
    }
  }

  &__cc-option {
    text-align: center;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    padding: 8px 20px;
    background-color: rgba(#e0e0e0, 0.05);
    font-weight: 500;
    cursor: pointer;
    width: fit-content;

    &--active {
      border: 1px solid #253788;
      background-color: rgba(#253788, 0.05);
    }
  }

  &__cc-block {
    display: flex;
    column-gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 2rem;
  }
}
</style>
