import api from '@/services/Api';

class Approvals {
  /**
   * Create a new approval
   * @returns {AxiosPromise<any>}
   */
  static create(payload) {
    return api.post('/approvals', payload);
  }

  /**
   * Delete an existing approval
   * @returns {AxiosPromise<any>}
   */
  static delete(id) {
    return api.delete(`/approvals/${id}`);
  }

  /**
   * Set new approval node
   * @returns {AxiosPromise<any>}
   */
  static setApprovalNode(payload) {
    return api.post('/approvals-trees', payload);
  }

  /**
   * Assign CC to a new Node
   * @returns {AxiosPromise<any>}
   */
  static assignCCToNewParent(id, payload) {
    return api.put(`/approvals-trees/${id}`, payload);
  }

  /**
   * Assign a new Vice Chair
   * @returns {AxiosPromise<any>}
   */
  static deleteApprovalNode(approvalId) {
    return api.delete(`/approvals-trees/${approvalId}`);
  }

  /**
   * Update a single Approval
   * @returns {AxiosPromise<any>}
   */
  static update(payload) {
    return api.put(`/approvals/${payload.id}`, payload);
  }

  /**
   * Submit a single Approval
   * @returns {AxiosPromise<any>}
   */
  static submit(id) {
    return api.post(`/approvals/${id}/submit`);
  }
}

export default Approvals;
