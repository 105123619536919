<template>
  <div class="container data-entry">
    <div class="card conference-card border-0 shadow bg-light">
      <div class="card-header">
        <div class="row">
          <div class="col my-auto">
            <ifac-loader
              v-if="conferenceDataLoading"
              :style="{ height: `${conferenceLoaderHeight}px` }"
            />
            <ifac-conference-header
              v-else
              with-pdf
              :statuses="statuses"
              header-type="overview"
              :conference="conference"
              :countries="countries"
              @conference-status="changeConferenceStatus"
            />
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col-12 text-right">
            <div class="d-inline-block" v-tooltip="tooltip">
              <router-link
                v-if="isEnabled"
                :to="conferenceReporUrl()"
                :class="!isSubmitted ? 'disabled' : ''"
                class="btn btn-primary continue-application float-none mr-3"
              >
                View report
              </router-link>
            </div>
            <router-link :to="detailsUrl()" class="btn btn-primary continue-application">
              View application
            </router-link>
          </div>
        </div>

        <!-- Conference Hierarchy Component -->
        <div
          class="overview__banner"
          v-if="displayViceChairBanner"
          @click="$bvModal.show('vice-chair-modal')"
        >
          Assign Technical Board Vice Chairs
        </div>
        <div v-if="displayHierarchyTree">
          <ifac-hierarchy-tree
            isAdmin
            class="mt-4"
            :data="[approvalsTree]"
            :key="approvalsTree.id"
            :isLoading="approvalsTreesLoading"
            v-for="approvalsTree in approvalsTrees"
            @approval-click="handleApprovalClick"
          />
        </div>
        <set-vice-chair-modal
          :conferenceId="id"
          :data="[approvalsTrees[0]]"
          v-if="approvalsTrees.length"
          @data-updated="fetchApprovalsTrees"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Conferences from '@/services/Api/Conferences';
import {
  IfacLoader,
  IfacConferenceHeader,
  IfacHierarchyTree,
  ConferenceStatuses,
} from '@ifac/ui';
import SetViceChairModal from '@/views/components/SetViceChairModal.vue';

export default {
  components: {
    IfacLoader,
    IfacConferenceHeader,
    IfacHierarchyTree,
    SetViceChairModal,
  },
  created() {
    this.fetchConference();
  },
  data() {
    return {
      conferenceDataLoading: false,
      conference: null,
      approvalsTrees: [],
      approvalsTreesLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      countries: 'countries/data',
      isEditable: 'conferences/isEditable',
    }),
    tooltip() {
      return !this.isSubmitted
        ? 'You cannot view or edit the report until it is submitted by the corresponding affiliate.'
        : '';
    },
    statuses() {
      const { open, historic, cancelled } = ConferenceStatuses;
      return Object.values(ConferenceStatuses).filter(
        (s) => s.id !== open.id && s.id !== historic.id,
      ).map((s) => {
        const status = {
          ...s,
          ...{ isHighlighted: s.id === cancelled.id },
        };
        return status;
      });
    },
    isSubmitted() {
      return this.conference?.report?.isSubmitted;
    },
    isEnabled() {
      const { completed } = ConferenceStatuses;
      return this.conference?.status === completed.id;
    },
    id() {
      return this.$route.params.id;
    },
    reportId() {
      return this.conference?.report?.id;
    },
    displayViceChairBanner() {
      const { submitted, released } = ConferenceStatuses;
      const validStatuses = [submitted.id, released.id];

      return (
        this.approvalsTrees?.length
        && !this.approvalsTreesLoading
        && validStatuses.includes(this.conference?.status)
      );
    },
    conferenceLoaderHeight() {
      return 167;
    },
    displayHierarchyTree() {
      const {
        submitted,
        released,
        approved,
        completed,
        published,
      } = ConferenceStatuses;
      const validStatuses = [submitted.id, released.id, approved.id, completed.id, published.id];

      return validStatuses.includes(this.conference?.status);
    },
  },
  methods: {
    async fetchConference() {
      this.conferenceDataLoading = true;
      const { data } = await Conferences.get(this.$route?.params?.id);
      this.conference = data?.data;
      this.conferenceDataLoading = false;

      if (this.displayHierarchyTree) this.fetchApprovalsTrees();
    },
    payload(value) {
      return {
        id: this.id,
        action: value,
      };
    },
    handleApprovalClick(node = {}) {
      this.$router.push({
        name: 'ApprovalsDetails',
        params: {
          id: this.id,
          approvalId: node.approval?.id,
        },
      });
    },
    async changeConferenceStatus(action) {
      try {
        const { data } = await Conferences.action(this.payload(action));
        this.conference = data.data;
        this.$snack.success({
          text: 'Status Updated!',
        });
        this.fetchApprovalsTrees();
      } catch (e) {
        console.error(e);
        this.$snack.success({
          text: 'Problem updating the status, try again please.',
        });
      }
    },
    defaultDetailsUrl() {
      return {
        name: 'ConferencesDetails',
        params: {
          id: this.id,
        },
      };
    },
    detailsUrlWithQueryParams(url) {
      if (this.isEditable(this.conference)) {
        url.query = { section: 1 };
      }
      return url;
    },
    detailsUrl() {
      const url = this.defaultDetailsUrl();
      return this.detailsUrlWithQueryParams(url);
    },
    conferenceReporUrl() {
      return {
        name: 'ConferencesReport',
        params: {
          id: this.id,
          reportId: this.reportId,
        },
      };
    },
    async fetchApprovalsTrees() {
      this.approvalsTreesLoading = true;
      try {
        const response = await Conferences.approvalTree(this.id);
        const approvalsTrees = response?.data?.data;

        if (response?.status === 200 && approvalsTrees) {
          this.approvalsTrees = approvalsTrees.reverse();
        }
      } catch (error) {
        console.log(error);
      }
      this.approvalsTreesLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.overview {
  &__banner {
    text-align: center;
    border: 2px solid #253788;
    border-radius: 3px;
    padding: 5px;
    margin-top: 2rem;
    cursor: pointer;
    background-color: rgba(#253788, 0.05);
    font-weight: 500;
  }
}
</style>
